import React, { useEffect, useState } from "react";
import "./styles/bootstrap.min.css";
import "./styles/style.css";
import Routes from "./routes/Routes.js";
import { Redirect, useHistory } from 'react-router-dom';
import { Toastify } from "./Theme";
import withLanguage from "./services/withLanguage";
import { checkSessionExpire, getUser } from "./Auth/user";
import "primereact/resources/themes/lara-light-indigo/theme.css";  //theme
import "primereact/resources/primereact.min.css";                  //core css
import "primeicons/primeicons.css";

// import './assets/vendor/owl.carousel/assets/owl.carousel.min.css';  
import './assets/vendor/owl.carousel/assets/owl.theme.default.css'; 

const scripts = [
  "./assets/vendor/jquery/jquery.min.js",
  "./assets/vendor/bootstrap/js/bootstrap.bundle.min.js",
  "./assets/vendor/jquery.easing/jquery.easing.min.js",
  "./assets/vendor/php-email-form/validate.js",
  "./assets/vendor/counterup/counterup.min.js",
  "./assets/vendor/isotope-layout/isotope.pkgd.min.js",
  "./assets/vendor/owl.carousel/owl.carousel.min.js",
  "./assets/vendor/waypoints/jquery.waypoints.min.js",
  "./assets/vendor/venobox/venobox.min.js",
  "./assets/vendor/aos/aos.js",
  "./assets/js/main.js",
  ];  

function App() {
  // const [isRedirectToDashboard, setIsRedirectToDashboard] = useState(false)
  checkSessionExpire();

  // const redirectToDashboard = (path) => {
  //   console.log(path)
  //   console.log('cccccccc')
  //   setIsRedirectToDashboard(true)
  //   return <Redirect to={path} />
  // }
  useEffect(() => {
    scripts.map((script) => {
      appendScript(script);
    });
  }, []);

  return (
    <div className="job-fair-app">
      <Routes />
      <Toastify />
    </div>
  );
}

function appendScript(scriptToAppend) {
  const script = document.createElement("script");
  script.src = `"${scriptToAppend}"`;
  script.async = true;

//   document.body.appendChild(script);
}


export default withLanguage(App);
