import React, { Component } from 'react';
import { ToastContainer, Zoom } from 'react-toastify';
import '../styles/ReactToastify.css';
 
class Toastify extends Component {
 
    render() {
        return (
            <ToastContainer
                limit={1}
                position="top-center"
                autoClose={3000}
                hideProgressBar
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnVisibilityChange={true}
                draggable
                pauseOnHover
                transition={Zoom}
                />
        );
    }
}
export default Toastify;