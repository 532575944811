import React from 'react';
import FormContext from './FormContext';
import { Password as PrimePass } from 'primereact/password';

const Password = ({ label, value, name, onChange, ...props}) => {

    function changeHandler(e) {
        onChange(e.target.name, e.target.value);
    }

    return <FormContext.Consumer>
        {context => {
            const errors = context? context.errors: {};
            return <>
                {label && <label className="form-label">{label}</label>}
                {/* <input type="password" 
                value={value || ''}
                className={`form-control ${errors[name]? 'has-error': ''}`} 
                name={name}
                {...props}
                onChange={changeHandler} /> */}
                <PrimePass value={value || ''} className={` ${errors[name]? 'p-invalid': ''}`} name={name}
                {...props} onChange={changeHandler} toggleMask feedback={false}/>
                {(errors[name] && errors[name].length > 0) && <div className="help-block error">{errors[name]}</div>}
            </>
        }}
    </FormContext.Consumer>
}
export default Password;