const initialState = {
    user: {},
};
  
const userData = (state = initialState, action) => {
    if (action === undefined) return state;
    switch (action.type) {
        case 'AUTH_USER':
            return {
                user: Object.assign({}, action.user),
            };
        default:
            return state;
    }
};
export default userData;