import React, {useState} from 'react'
import { Carousel } from 'primereact/carousel';
import { Button } from 'primereact/button';
import brandsData from './BrandsData.json';
import './BrandsCarousel.css'

function BrandSlider(props) {
    const responsiveOptions = [
        {
            breakpoint: '1024px',
            numVisible: 3,
            numScroll: 3
        },
        {
            breakpoint: '600px',
            numVisible: 2,
            numScroll: 2
        },
        {
            breakpoint: '480px',
            numVisible: 1,
            numScroll: 1
        }
    ];
    const productTemplate = (product) => {
        return (
            <div className="product-item">
                <div className="product-item-content">
                    <div className="mb-3">
                        <img src={`${product.image}`} onError={(e) => e.target.src=`${product.image}`} alt={product.name} className="product-image" />
                    </div>
                </div>
            </div>
        );
    }
    return (
        <div className="carousel-demo">
            <Carousel value={brandsData.data} numVisible={ props === "Employee" ? 3 : 4} numScroll={1} responsiveOptions={responsiveOptions} className="custom-carousel" circular
              autoplayInterval={3000} itemTemplate={productTemplate} header="" />
        </div>
    )
}

export default BrandSlider