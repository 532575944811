import React, { Component } from 'react';
import { Link, Redirect, useLocation, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { isEmpty } from '../helpers';
import { getUser, removeUser } from '../Auth/user';
import BurgerMenu from './BurgerMenu';
import web from '../config/web';
import { Ajax } from '../api';
import authUserAction from '../services/User/actions';
import userIcon from '../assets/images/user.png';
import logo from '../assets/images/logo1.png';
import './layout.css';

class Header extends Component {
	_isMounted = false;
	constructor(props) {
		super(props);
		this.state = { 
			menu: this.getMenu(), 
			redirectTo: ''
		 };
	}

	componentDidMount() {
		this._isMounted = true;
		console.log('Check header props', this.props);
	}

	componentDidUpdate(prevProps) {
		if (
			JSON.stringify(prevProps.authUser) !==
			JSON.stringify(this.props.authUser)
		) {
			let menu = this.getMenu();
			this.loadProfileImage();
			this.setState({ menu });
		}
	}

	loadProfileImage() {
		let user = getUser();
		if (!isEmpty(user) && user.role === '2') {
			Ajax.get(web.baseUrl + 'employer/getemployerprofileimage', {
				username: user.username,
			})
				.then((data) => {
					if (data.content) {
						if (data.content.imageData !== '') {
							let { authUser } = this.props;
							authUser.imageData = data.content.imageData;
							authUser.imageContentType =
								data.content.imageContentType;
							this.props.dispatch(authUserAction(authUser));
						}
					}
				})
				.catch((e) => e);
		}
	}
	componentWillUnmount = () => (this._isMounted = false);
	setState = (state, callback) =>
		this._isMounted && super.setState(state, callback);

	getMenu() {
		var user = getUser();
		if (isEmpty(user)) {
			return {};
		}
		if (user.role === '1') {
			return [
				{ link: '/jfuser/matched-jobs', title: 'Matched Jobs' },
				{ link: '/jfuser/edit-profile', title: 'Profile' },
			];
		} else if (user.role === '2') {
			return [{ link: '/employer/edit-profile', title: 'Profile' }];
		}
	}

	logout() {
		removeUser();
		let url = '/';
		let href = window.location.href;
		if (href.indexOf('job-fair') >= 0) {
			url = '/job-fair/login';
		}
		window.location.href = url;
	}

	printMenus() {
		var menus = [];
		var { menu } = this.state;
		for (let i in menu) {
			menus.push(
				<li key={i}>
					<Link to={menu[i].link}>{menu[i].title}</Link>
				</li>
			);
		}
		return menus;
	}

	render() {
		let user = getUser();
		let dashboardLogoLink = user?.role === '2' ? '/employer' : user?.role === '1' ? '/jfuser/dashboard' : '/';
		console.log('user tt',user)
		var { menu, redirectTo } = this.state;
		var { authUser } = this.props;
		var profileIcon = userIcon;
		if (authUser.imageData && authUser.imageData !== '') {
			profileIcon = `data:${authUser.imageContentType};charset=utf-8;base64, ${authUser.imageData}`;
		}

		return (
			<>
				<header id="header" className="fixed-top">
					<div className="container-fluid">
					<div className="row">
					<div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
						<div className="logo">
							<Link
								to={dashboardLogoLink}
								onClick={() => {
									let intro =
										document.getElementById('intro');
									if (intro)
										intro.scrollIntoView({
											behavior: 'smooth',
										});
								}}
							>
								<img src={logo} className="img-fluid" />
								{/* <div className="logo-img"></div> */}
							</Link>
						</div>
						</div>
						{/* <button type="button" className="mobile-nav-toggle d-lg-none">
              <i className="fa fa-bars"></i>
            </button> */}
			<div className="col-sm-12 col-md-8 col-lg-8 col-xl-8">
						<nav className="main-nav float-right d-none d-lg-block mt-2">
							<ul>
								{isEmpty(user) ? (
									<>
										
										<li>
											<Link to="/login" className="default-btn mr-4">Login </Link>
										</li>
										<li>
											<Link to={{
												pathname: "/signup",
												state: {
												fields: { role: "1" }
												}
											}} className="default-btn signup-btn">Sign Up </Link>
										</li>
									</>
								) : (
									<>
										{user?.role === '1' && (
											<>
												<li>
													<NavLink
														to="/jfuser/dashboard"
														activeClassName="active-header"
													>
														Dashboard
													</NavLink>
												</li>
												<li className="drop-down">
													<a href="#">My Services</a>
													<ul className="dropdown">
														<li>
															<NavLink
																activeClassName="active-header"
																to="/jfuser/add-lead"
															>
																Recharge Wallet
															</NavLink>
														</li>
														<li>
															<NavLink
																activeClassName="active-header"
																to="/jfuser/my-calls"
															>
																My Calls
															</NavLink>
														</li>
													</ul>
												</li>
												{/*<li className="drop-down">
                          <a href="#">Help/Support</a>
                          <ul className="dropdown">
                            <li>
                              <NavLink activeClassName="active-header" to="/jfuser/tnc">
                                Terms &amp; Conditions
                              </NavLink>
                            </li>
                            <li>
                              <Link to="/jfuser/faq">FAQ</Link>
                            </li>
                          </ul>
                        </li>*/}
											</>
										)}

										{user?.role === '2' && (
											<>
												<li>
													<NavLink
														activeClassName=""
														to="/employer"
													>
														Dashboard
													</NavLink>
												</li>
												<li>
													<NavLink
														activeClassName=""
														to="/employer/post-job"
													>
														Post Job
													</NavLink>
												</li>
												<li className="drop-down">
													<a href="#">My Services</a>
													<ul className="dropdown">
														<li>
															<NavLink
																activeClassName="active-header"
																to="/employer/add-lead"
															>
																Recharge Wallet
															</NavLink>
														</li>
														<li>
															<NavLink
																activeClassName="active-header"
																to="/employer/my-calls"
															>
																My Calls
															</NavLink>
														</li>
													</ul>
												</li>
												{/* <li className="drop-down">
													<a href="#">
														Business Product
													</a>
													<ul className="dropdown">
														<li>
															<NavLink
																activeClassName="active-header"
																to="/employer/products"
															>
																Products
															</NavLink>
														</li>
														<li>
															<NavLink
																activeClassName="active-header"
																to="/employer/my-orders"
															>
																My Orders
															</NavLink>
														</li>
													</ul>
												</li> */}
												{/*<li className="drop-down">
                          <a href="#">Help/Support</a>
                          <ul className="dropdown">
                            <li>
                              <NavLink activeClassName="active-header" to="/employer/tnc">
                                Terms &amp; Conditions
                              </NavLink>
                            </li>
                            <li>
                              <NavLink activeClassName="active-header" to="/employer/faq">FAQ</NavLink>
                            </li>
                          </ul>
                        </li*/}
											</>
										)}
										<li>
											<Link
												className="notification"
												to={`${
													user?.role === '2'
														? '/employer'
														: '/jfuser'
												}/notifications`}
												style={{ cursor: 'pointer' }}
											>
												<i
													className="fa fa-bell"
													aria-hidden="true"
												></i>
												{authUser.notifCount > 0 ? (
													<div className="badge">
														{authUser.notifCount}
													</div>
												) : (
													''
												)}
											</Link>
										</li>
										<li className="drop-down">
											<span>
												<img
													src={profileIcon}
													className="profile-image"
													alt={user?.username}
												/>
											</span>
											<ul className="dropdown profile-menu">
												{!isEmpty(menu) && (
													<>{this.printMenus()}</>
												)}
												{user.role === '2' && (
													<li className="drop-down">
														<a href="#">
															Business Product
														</a>
														<ul className="dropdown">
															<li>
																<NavLink
																	activeClassName="active-header"
																	to="/employer/products"
																>
																	Products
																</NavLink>
															</li>
															<li>
																<NavLink
																	activeClassName="active-header"
																	to="/employer/my-orders"
																>
																	My Orders
																</NavLink>
															</li>
														</ul>
													</li>
												)}
												<li>
													<a
														style={{
															cursor: 'pointer',
														}}
														onClick={this.logout}
													>
														Logout
													</a>
												</li>
												{/*<li>
                          <Link to="/contact-us">Contact Us</Link>
                        </li>*/}
											</ul>
										</li>
									</>
								)}
							</ul>
						</nav>
						</div>
						</div>
					</div>
					<BurgerMenu menu={menu} logout={this.logout.bind(this)} />
				</header>
 
				{redirectTo && <Redirect to={redirectTo} />}
			</>
		);
	}
}
const mapStateToProps = (state) => {
	return {
		authUser: state.user.user,
	};
};
export default connect(mapStateToProps)(Header);
