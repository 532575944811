import React, { Component } from 'react';
import { FormRow, Input } from '../../Theme';
import Checkbox from './Checkbox';
import qualification from '../../config/qualification';
import Nouislider from 'react-nouislider';
import '../../styles/range-slider.css';
import { Ajax } from '../../api';
import web from '../../config/web';
import _profile from '../../i18n/profile.json';
import citiesJson from '../../assets/cities/city.json';
import MultiSelect from 'react-multi-select-component';
import { getUser } from '../../Auth/user';
// import Autocomplete from "../../lib/Autocomplete";
import { Select } from 'antd';
const { Option } = Select;

class Filter extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);
		this.state = {
			keywords: [],
			location: [],
			qualifications: [],
			jobTitles: [],
			experience: [0, 10],
			salary: [0, 40],
			cities: citiesJson,
			defaultLocation: [],
			// defaultKeywords: null
			//city: "",
		};
	}

	componentDidMount() {
		this._isMounted = true;
		let { keywords, qualifications } = this.state;
		keywords.push(_profile[this.props.loc].all);
		console.log(keywords);
		qualifications.push(_profile[this.props.loc].all);
		this.setState({ keywords, qualifications });
		this.getJobTitles();
		this.populateFilters();
		let { location } = window;
		const query = new URLSearchParams(location.search);
		const jobKey = query.get('jobKey');
		if(jobKey){
			let defaultK = [{
				label: jobKey,
				value: jobKey
			}]
			this.setState({ keywords : defaultK })
		}
		
	}
	componentWillUnmount = () => (this._isMounted = false);
	setState = (state, callback) =>
		this._isMounted && super.setState(state, callback);

	//   getJobTitles() {
	//     Ajax.get(web.baseUrl + "job/getjobtitle")
	//       .then((data) => {
	//         if (data.content) {
	//           let jobTitles = data.content.filter((title) => title.jobTitle);
	//           this.setState({ jobTitles });
	//         }
	//       })
	//       .catch((e) => e);
	//   }

	getJobTitles() {
		Ajax.get(web.baseUrl + 'job/getjobtitle')
			.then((data) => {
				if (data.content) {
					let titles = data.content.filter((title) => title.jobTitle);
					let jobTitles = titles.map((title) => ({
						label: title.jobTitle,
						value: title.jobTitle,
					}));
					//   jobTitles.push({ label: "Tester", value: "Tester" });
					//   jobTitles.push({ label: "QA", value: "QA" });

					this.setState({ jobTitles });
				}
			})
			.catch((e) => e);
	}

	populateFilters = () => {
		let { location } = window;
		if (!location.search) {
			// console.log('this.props.currentUser', this.props.currentUser);
			// console.log(this.props && this.props.currentUser && this.props.currentUser.city)
			// let currentUserDesignation = this.props.currentUser && this.props.currentUser.experience.length > 0 ?
			// this.props.currentUser.experience.map((d) => d.domainOfExperience) : "";
			// this.props.filterJobs(currentUserDesignation.length > 0 ? currentUserDesignation.join(",") : "", '', 0, 0, 0, 0, this.props && this.props.currentUser && this.props.currentUser.city, '');
			this.props.filterJobs('', '', 0, 10, 0, 40, '', '');
		} else {
			
			var query = new URLSearchParams(location.search);
			var jobKey = query.get('jobKey');
			var loc = query.get('location');
			var qualification = query.get('qualification');
			var experience = query.get('expRange');
			var maxExp = query.get('maxExpRange');

			var salary = query.get('salary');
			var maxSalary = query.get('maxSalary');
			//var city = query.get("city");

			let keywords =
				jobKey === ''
					? [_profile[this.props.loc].all]
					: jobKey.split(',');
			let qualifications =
				qualification === '' ? ['All'] : qualification.split(',');
			this.setState({
				keywords,
				qualifications,
				experience: [Number(experience), Number(maxExp)],
				maxExp: [0, parseInt(maxExp)],
				salary: [Number(salary), Number(maxSalary)],
				maxSalary: [0, parseInt(maxSalary)],
				location: loc,
				city: loc,
			});
			this.props.filterJobs(
				jobKey,
				qualification,
				experience,
				maxExp,
				salary,
				maxSalary,
				loc,
				loc
			);
		}
	};

	handleKeywordCheck = (name, value, isChecked) => {
		let { keywords } = this.state;
		if (!isChecked) {
			const index = keywords.indexOf(value);
			if (index > -1) {
				keywords.splice(index, 1);
			}
		} else {
			if (value !== 'All' && keywords.includes('All')) {
				keywords.splice(keywords.indexOf('All'), 1);
				keywords.push(value);
			} else if (value === 'All') {
				keywords = ['All'];
			} else {
				keywords.push(value);
			}
		}
		this.setState({ keywords });
	};

	handleQualCheck = (name, value, isChecked) => {
		let { qualifications } = this.state;
		if (!isChecked) {
			const index = qualifications.indexOf(value);
			if (index > -1) {
				qualifications.splice(index, 1);
			}
		} else {
			if (value !== 'All' && qualifications.includes('All')) {
				qualifications.splice(qualifications.indexOf('All'), 1);
				qualifications.push(value);
			} else if (value === 'All') {
				qualifications = ['All'];
			} else {
				qualifications.push(value);
			}
		}
		//console.log(qualifications);

		this.setState({ qualifications });
	};

	setExperience = (range) => {
		console.log('before', range);
		range[0] = range[0] === 'Fresher' ? 0 : range[0];
		range[1] = range[1] === 'Fresher' ? 0 : range[1];
		console.log('after', range);
		this.setState({ experience: range });
	};

	setSalary = (range) => {
		range[0] = parseInt(range[0]);
		range[1] = parseInt(range[1]);
		this.setState({ salary: range });
	};

	applyFilter = () => {
		let { keywords, location, qualifications, experience, salary } =
			this.state;
		console.log(keywords);
		var k = keywords
			.filter((item) => item != 'All')
			.map((i) => i.value)
			.join(',')
			.replace(',', '');

		console.log(k, keywords);
		var q = qualifications.includes('All') ? '' : qualifications.join(',');
		var queryParams = new URLSearchParams(window.location.search);
		queryParams.set('jobKey', k);
		queryParams.set('location', location);
		queryParams.set('qualification', q);
		queryParams.set('expRange', experience[0]);
		queryParams.set('maxExpRange', experience[1]);
		queryParams.set('salary', salary[0]);
		queryParams.set('maxSalary', salary[1]);
		//queryParams.set("city", city);

		window.history.replaceState(null, null, '?' + queryParams.toString());
		this.props.filterJobs(
			k,
			q,
			experience[0],
			experience[1],
			salary[0],
			salary[1],
			location,
			location
			//city
		);
	};

	getCityDropDown() {
		const options = [];
		if (this.state.cities && this.state.cities.length > 0) {
			for (let i = 0; i < this.state.cities.length; i++) {
				options.push(
					<Option key={i} value={this.state.cities[i].label}>
						{this.state.cities[i].label}
					</Option>
				);
			}
		}
		return options;
	}
	locationHanlder = (value) => {
		const locations = value.join();
		this.setState({ location: locations });
	};

	discardFilter = () => {
		var uri = window.location.toString();
		var clean_uri = uri.substring(0, uri.indexOf('?'));
		window.history.replaceState({}, null, clean_uri);
		this.props.filterJobs('DEFAULT', '', 0, 10, 0, 0, '', '');
		this.setState({
			keywords: [],
			location: [],
			qualifications: [],
			experience: [0, 10],
			salary: [0, 40],
			cities: citiesJson,
			defaultLocation: []
		});
		
	};

	render() {
		let {
			keywords,
			location,
			qualifications,
			experience,
			salary,
			jobTitles,
			//city,
		} = this.state;
		// console.log(cities);
		//console.log(experience);
		let { loc } = this.props;
		// let { location } = window;
		// const query = new URLSearchParams(location.search);
		// const cityLocation = query.get('location');
        // if(cityLocation){
        //     this.setState({ defaultLocation : cityLocation && cityLocation.length > 0 && cityLocation.split(',')})
        // }
		// if(jobKey){
		// 	let defaultK = [{
		// 		label: jobKey,
		// 		value: jobKey
		// 	}]
		// 	this.setState({ keywords : defaultK })
		// }
		return (
			<div className={`${this.props.isDown ? 'slidedown' : 'slideup'}`}>
				<FormRow>
					<div className="col-xs-12 col-sm-12">
						<div className="filter-label">
							{_profile[loc].job_experience}
						</div>
						<Nouislider
							range={{ min: 0, max: 10 }}
							start={experience}
							connect={true}
							tooltips
							step={1}
							format={{
								to: function (value) {
									console.log('to=>', value);
									return value === 0 ? 'Fresher' : value;
								},
								from: function (value) {
									console.log('from=>', value);

									return value === 'Fresher' ? 0 : value;
								},
							}}
							onChange={(range) => this.setExperience(range)}
						/>
					</div>
				</FormRow>
				<hr />
				<FormRow>
					<div className="col-xs-12 col-sm-12">
						<div className="filter-label">
							{_profile[loc].job_keywords}
						</div>
						<MultiSelect
							hasSelectAll={false}
							options={jobTitles}
							value={keywords}
							onChange={(d) => this.setState({ keywords: d })}
							labelledBy={'Select'}
						/>
						{/* <Checkbox
              label="All"
              name="job_keyword"
              value="All"
              handleChecked={this.handleKeywordCheck.bind(this)}
              checked={keywords.includes("All")}
            />
            {jobTitles.map((title, i) => (
              <Checkbox
                key={i}
                label={title.jobTitle}
                name="keywords"
                value={title.jobTitle}
                handleChecked={this.handleKeywordCheck.bind(this)}
                checked={keywords.includes(title.jobTitle)}
              />
            ))} */}
					</div>
				</FormRow>
				<hr />
				{/* <FormRow>
          <div className="col-xs-12 col-sm-7" hidden>
            <div className="filter-label">{_profile[loc].location}</div>
            <Input
              name="location"
              value={location}
              onChange={(name, value) => this.setState({ location: value })}
            />
          </div>
        </FormRow> 
        <hr />*/}
				<FormRow>
					<div className="col-xs-12 col-sm-12">
						<div className="filter-label">
							{_profile[loc].location}
						</div>
						<Select
							mode="multiple"
							allowClear
							value={this.state.location && this.state.location.length > 0 ? this.state.location.split(',') : []}
							style={{ width: '100%' }}
							placeholder="Please select any location"
							onChange={this.locationHanlder}
						>
							{this.getCityDropDown()}
						</Select>
						{/* <Autocomplete
              getItemValue={(item) => item.label}
              items={cities}
              placeholder={_profile[loc].location}
              value={location}
              onChange={(e) => this.setState({ location: e.target.value })}
              onSelect={(val) => this.setState({ location: val })}
            /> */}
					</div>
				</FormRow>
				<hr />
				<FormRow>
					<div className="col-xs-12">
						<div className="filter-label">
							{_profile[loc].minimum_qualification}
						</div>
						<Checkbox
							label="All"
							name="qualifications"
							value="All"
							handleChecked={this.handleQualCheck.bind(this)}
							checked={qualifications.includes('All')}
						/>
						{qualification.map((qual, i) => (
							<Checkbox
								key={i}
								label={qual.value}
								name="qualifications"
								value={qual.value}
								handleChecked={this.handleQualCheck.bind(this)}
								checked={qualifications.includes(qual.value)}
							/>
						))}
					</div>
				</FormRow>
				<hr />
				<FormRow>
					<div className="col-xs-12 col-sm-12">
						<div className="filter-label">
							{_profile[loc].salary_expectation}
						</div>
						<Nouislider
							range={{ min: 0, max: 85 }}
							start={salary}
							connect={true}
							tooltips
							step={5}
							format={{
								to: function (value) {
									return parseInt(value) + 'K';
								},
								from: function (value) {
									return Number(value.replace('K', ''));
								},
							}}
							onChange={(range) => this.setSalary(range)}
						/>
					</div>
				</FormRow>
				<hr />
				<FormRow>
					<div className="col-xs-12" style={{ paddingTop: '20px' }}>
						<button
							type="button"
							className="btn btn-secondary mr-10"
							onClick={this.discardFilter}
						>
							{_profile[loc].discard}
						</button>
						<button
							type="button"
							className="btn btn-primary"
							onClick={this.applyFilter}
						>
							{_profile[loc].apply}
						</button>
					</div>
				</FormRow>
			</div>
		);
	}
}
export default Filter;
