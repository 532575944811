import React, { Component } from 'react';
import { slide as Menu } from 'react-burger-menu';
import '../styles/burger.css';
import { Link } from 'react-router-dom';
import { isEmpty } from '../helpers';
import { getUser } from '../Auth/user';

class BurgerMenu extends Component {
	constructor(props) {
		super(props);
		this.state = {
			menuOpen: false,
			menu: this.props.menu,
		};
	}

	signOut = () => {
		this.props.logout();
	};

	componentDidUpdate(prevProps) {
		if (
			Object.keys(prevProps.menu).length !==
			Object.keys(this.props.menu).length
		) {
			this.setState({ menu: this.props.menu });
		}
	}

	handleStateChange = (state) => this.setState({ menuOpen: state.isOpen });

	closeMenu = () => this.setState({ menuOpen: false });

	printMenus() {
		var { menu } = this.props;
		var menus = [];
		for (let i in menu) {
			menus.push(
				<Link
					onClick={this.closeMenu}
					className="menu-item"
					style={{ display: 'block' }}
					key={i}
					to={menu[i].link}
				>
					{menu[i].title}
				</Link>
			);
		}
		return menus;
	}

	render() {
		let { menu, menuOpen } = this.state;
		let user = getUser();
		return (
			<>
				{this.props.menu && (
					<div className="burger-menu right">
						<Menu
							right={true}
							isOpen={menuOpen}
							onStateChange={(state) =>
								this.handleStateChange(state)
							}
						>
							{isEmpty(user) ? (
								<Link
									onClick={this.closeMenu}
									className="menu-item"
									to="/login"
									style={{ display: 'block' }}
								>
									Login
								</Link>
							) : (
								<>
									{user?.role === '2' && (
										<>
											<Link
												onClick={this.closeMenu}
												to="/employer"
												className="menu-item"
												style={{ display: 'block' }}
											>
												Dashboard
											</Link>
										</>
									)}
									{user?.role === '1' && (
										<>
											<Link
												onClick={this.closeMenu}
												to="/jfuser/dashboard"
												className="menu-item"
												style={{ display: 'block' }}
											>
												Dashboard
											</Link>
										</>
									)}
									{!isEmpty(menu) && <>{this.printMenus()}</>}
									{user?.role === '2' && (
										<>
											<Link
												onClick={this.closeMenu}
												to="/employer/products"
												className="menu-item"
												style={{ display: 'block' }}
											>
												Recharge Wallet
											</Link>
											<Link
												onClick={this.closeMenu}
												to="/employer/my-orders"
												className="menu-item"
												style={{ display: 'block' }}
											>
												My Calls
											</Link>
											<Link
												onClick={this.closeMenu}
												to="/employer/products"
												className="menu-item"
												style={{ display: 'block' }}
											>
												Products
											</Link>
											<Link
												onClick={this.closeMenu}
												to="/employer/my-orders"
												className="menu-item"
												style={{ display: 'block' }}
											>
												My Orders
											</Link>
										</>
									)}
									{user?.role === '1' && (
										<>
											<Link
												onClick={this.closeMenu}
												to="/jfuser/add-lead"
												className="menu-item"
												style={{ display: 'block' }}
											>
												Recharge Wallet
											</Link>
											<Link
												onClick={this.closeMenu}
												to="/jfuser/my-calls"
												className="menu-item"
												style={{ display: 'block' }}
											>
												My Calls
											</Link>
										</>
									)}
									<span
										onClick={this.signOut}
										className="menu-item"
										style={{ display: 'block' }}
									>
										Logout
									</span>
									<Link
										onClick={this.closeMenu}
										to="/contact-us"
										className="menu-item"
										style={{ display: 'block' }}
									>
										Contact Us
									</Link>
								</>
							)}
						</Menu>
					</div>
				)}
			</>
		);
	}
}
export default BurgerMenu;
