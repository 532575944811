import React, { Component } from 'react';
import {
	Animate,
	Body,
	Content,
	Card,
	Container,
	Row,
	FormRow,
	Form,
	Input,
	Paginate,
	YerJobList,
} from '../../Theme';
import { withRouter } from "react-router-dom";
import Meta from '../../Layout/Meta';
import { Ajax } from '../../api';
import web from '../../config/web';
import { isEmpty } from '../../helpers';
import { connect } from 'react-redux';
import JobCard from '../Job/JobCard';
import _job from '../../i18n/job.json';
import Filter from './../Dashboard/Filter';
import JobDescription from '../Job/JobDescription';
import { getUser } from '../../Auth/user';
import { Button } from 'antd';
import BrandSlider from "./../Dashboard/BrandSlider";

class Jobs extends Component {
	_isMounted = false;
	jobs = [];

	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			jobs: [],
			currentPage: 1,
			totalRecords: 0,
			isDown: false,
			showDesc: false,
			job: {},
			listIndex: '',
			search: '',
			jobTags: [],
			selectedTags: [],
		};
		this.selectFilterTag = this.selectFilterTag.bind(this);
	}

	componentDidMount() {
		window.scrollTo(0, 0)
		this._isMounted = true;
		// this.getJobTitles();
        if(Object.keys(this.props.authUser).length > 0){
		    this.getTags();
        }
	}
	//select tag by click handler
	selectFilterTag(tag) {
		let filterTags = [];
		if (this.state.selectedTags.includes(tag)) {
			filterTags = this.state.selectedTags.filter((d) => d !== tag);
		} else {
			this.state.selectedTags.push(tag);
			filterTags = this.state.selectedTags;
		}
		this.setState({ selectedTags: filterTags });
		this.filterJobs(filterTags.join(','), '', 0, 0, 0, 0, '', '');
	}
	checkIsTagSelected(tag) {
		if (this.state.selectedTags.includes(tag)) {
			return true;
		} else {
			return false;
		}
	}
	//getTags
	getTags() {
		let { authUser } = this.props;
		Ajax.get(
			web.baseUrl +
				'job/getjobtitlesForEmployee?employeeId=' +
				authUser.id
		)
			.then((data) => {
				if (data.content && Array.isArray(data.content)) {
					this.setState({ jobTags: data.content });
				}
			})
			.catch((e) => e);
	}
	//getjobtitle
	// getJobTitles() {
	// 	Ajax.get(web.baseUrl + 'job/getjobtitle')
	// 		.then((data) => {
	// 			if (data.content) {
	// 				let titles = data.content.filter((title) => title.jobTitle);
	// 				let jobTitles = titles.map((title) => ({
	// 					label: title.jobTitle,
	// 				}));
	// 				this.setState({ jobTitles });
	// 			}
	// 		})
	// 		.catch((e) => e);
	// }
	componentWillUnmount = () => (this._isMounted = false);
	setState = (state, callback) =>
		this._isMounted && super.setState(state, callback);

	getJobs(params) {
		this.setState({ loading: true });
		let { authUser } = this.props;
		params.salary = params.salary * 1000;
		params.maxSalary = params.maxSalary * 1000;
		params.employeeId = Object.keys(authUser).length > 0 ? authUser.id : 0;
		Ajax.get(web.baseUrl + 'job/getfilteredjobpost', params)
			.then((data) => {
				if (data.content) {
					this.jobs = data.content.jobPostList;
					this.setState({
						totalRecords: data.content.count,
						jobs: data.content.jobPostList,
						currentPage: params.pageNumber,
						loading: false,
					});
					return;
				}
				this.setState({ loading: false });
			})
			.catch(() => this.setState({ loading: false }));
	}

	onPageChanged = (data) => {
		const { currentPage } = data;
		let { location } = window;
		var params = {};
		if (!location.search) {
			params = {
				expRange: 0,
				jobKey: this.getDefaultDomains(),
				pageNumber: currentPage,
				location: '',
				salary: 0,
				qualification: '',
				recordCount,
			};
		} else {
			var query = new URLSearchParams(location.search);
			var jobKey = query.get('jobKey');
			var loc = query.get('location');
			var qualification = query.get('qualification');
			var experience = query.get('expRange');
			var maxExpRange = query.get('maxExpRange');
			var salary = query.get('salary');
			var maxSalary = query.get('maxSalary');

			params = {
				maxExpRange,
				maxSalary,
				expRange: experience,
				jobKey,
				pageNumber: currentPage,
				location: loc,
				salary,
				qualification,
				recordCount,
			};
		}
		this.getJobs(params);
	};

	filterJobs = (
		keywords,
		quals,
		exps,
		maxExps,
		sals,
		maxSalary,
		loc,
		city
	) => {
		console.log(keywords, quals, exps, maxExps, sals, maxSalary, loc,city);
		this.setState({ isDown: false });
		let jobKey =
			keywords === 'DEFAULT' ? this.getDefaultDomains() : keywords;
		let params = {
			expRange: exps,
			maxExpRange: maxExps,
			jobKey,
			pageNumber: 1,
			location: loc,
			salary: sals,
			maxSalary: maxSalary,
			qualification: quals,
			recordCount,
			city,
		};
		this.getJobs(params);
	};

	searchJobs = (e) => {
		e.preventDefault();
		this.searchTextJobs();
	};

	changeSearch = (name, value) => {
		this.setState({ search: value }, () => {
			this.searchTextJobs();
		});
	};

	searchTextJobs = () => {
		let { search } = this.state;
		let jobs = this.jobs.filter(
			(job) =>
				job.jobTitle.toLowerCase().includes(search.toLowerCase()) ||
				job.companyName.toLowerCase().includes(search.toLowerCase()) ||
				job.city.toLowerCase().includes(search.toLowerCase()) ||
				job.locality.toLowerCase().includes(search.toLowerCase())
		);
		this.setState({ jobs });
	};

	getDefaultDomains() {
		let { authUser } = this.props;
		var jobKey = '';
		if (authUser.experience) {
			jobKey = authUser.experience.map((exp) => exp.domainOfExperience);
			jobKey = jobKey.join(',');
		}
		return jobKey;
	}

	showJobDescription = (listIndex, job, showDesc) => {
        sessionStorage.setItem('showJobDescriptionListIndex',listIndex)
        sessionStorage.setItem('showJobDescription',JSON.stringify(job))
        sessionStorage.setItem('showDesc',showDesc)
        sessionStorage.setItem('redirectFrom','home_job')
        sessionStorage.setItem('setDefaultFilter', window.location.search)
        this.props.history.push('/login')
		// this.setState({ listIndex, job, showDesc });
	};

	bindContactDetails = (content) => {
		let { job, jobs, listIndex } = this.state;
		job = { ...job, ...content };
		job.applied = true;
		jobs[listIndex] = job;
		this.setState({ job, jobs });
	};

	render() {
		let {
			totalRecords,
			currentPage,
			jobs,
			loading,
			isDown,
			showDesc,
			job,
			listIndex,
			search,
			jobTags
		} = this.state;
		let { loc, authUser } = this.props;
		return (
			<>
				<Meta title={_job[loc].employee_dashboard} />
				<Body>
					<Content>
						<Container>
							<Row>
								<div
									className={
										'col-xs-12 col-sm-12 col-md-4 col-lg-3 box-panel filter-section ' +
										(isDown ? 'open' : '')
									}
								>
									<div
										className="filter-section-overlay"
										onClick={() =>
											this.setState({
												isDown: !isDown,
											})
										}
									></div>
									<div className="filter-section-content">
										<Filter
											isDown={true}
											loc={loc}
											currentUser={authUser}
											filterJobs={this.filterJobs.bind(
												this
											)}
										/>
									</div>
								</div>
								<div className="col-xs-12 col-sm-12 col-md-8 col-lg-9  searched-section">
									<Animate>
										<Card>
											{/* <Row>
												<div className="col-sm-12 mb-5 mt-5">
													<BrandSlider fromSection="Employee"/>
												</div>
											</Row> */}
											<FormRow>
												<div className="col-xs-12">
													<Form
														errors={{}}
														onSubmit={
															this.searchJobs
														}
														style={{
															padding: '15px',
														}}
													>
														<FormRow>
															<div className="col-xs-10 col-sm-10 col-md-12 col-lg-12">
																<div className="form-group">
																	<div className="input-group">
																		<Input
																			name="search"
																			value={
																				search
																			}
																			placeholder={
																				_job[
																					loc
																				]
																					.search_jobs_here
																			}
																			onChange={
																				this
																					.changeSearch
																			}
																		/>
																		<span className="input-group-btn">
																			<button
																				className="btn btn-primary"
																				type="submit"
																			>
																				<span
																					className="glyphicon glyphicon-search"
																					aria-hidden="true"
																				></span>{' '}
																				{
																					_job[
																						loc
																					]
																						.search
																				}
																			</button>
																		</span>
																	</div>
																</div>
																<div className="form-group tagged-section">
																	<Button
																		type={
																			this.checkIsTagSelected(
																				''
																			)
																				? 'primary'
																				: ''
																		}
																		onClick={() =>
																			this.selectFilterTag(
																				''
																			)
																		}
																	>
																		All
																	</Button>{' '}
																	{jobTags.length > 0 && jobTags.map(
																		(d) => (
																			<>
																				<Button
																					type={
																						this.checkIsTagSelected(
																							d.jobTitle.trim()
																						)
																							? 'primary'
																							: ''
																					}
																					onClick={() =>
																						this.selectFilterTag(
																							d.jobTitle.trim()
																						)
																					}
																				>
																					{d.jobTitle.trim()}
																				</Button>{' '}
																			</>
																		)
																	)}
																</div>
															</div>
															<div className="col-xs-1 col-sm-1">
																<button
																	type="button"
																	className="btn btn-secondary btn-filter"
																	onClick={() =>
																		this.setState(
																			{
																				isDown: !isDown,
																			}
																		)
																	}
																>
																	<i
																		className="fa fa-filter"
																		aria-hidden="true"
																	></i>
																	{/* Filter */}
																</button>
															</div>
														</FormRow>
													</Form>
												</div>
											</FormRow>
										</Card>
										{loading ? (
											<YerJobList />
										) : (
											<>
												{!isEmpty(jobs) ? (
													<>
														{jobs.map((job, i) => (
															<JobCard
																key={i}
																job={job}
																loc={loc}
																index={i}
																authUser={authUser}
																showJobDescription={this.showJobDescription.bind(
																	this
																)}
															/>
														))}
													</>
												) : (
													<>
														<h3>
															{
																_job[loc]
																	.no_jobs_found
															}
														</h3>
														{/* <button
															type="button"
															className="btn btn-secondary"
															onClick={() =>
																this.setState({
																	isDown: !isDown,
																})
															}
														>
															<i
																className="fa fa-filter"
																aria-hidden="true"
															></i>
															Filter
														</button> */}
													</>
												)}
											</>
										)}

										{totalRecords > recordCount && (
												<Row>
													<div className="col-xs-12 text-center">
														<Paginate
															totalRecords={
																totalRecords
															}
															pageLimit={
																recordCount
															}
															pageNeighbours={2}
															currentPage={
																currentPage
															}
															showStats={true}
															onPageChanged={
																this
																	.onPageChanged
															}
														/>
													</div>
												</Row>
										)}
									</Animate>
								</div>
							</Row>
							{showDesc && (
								<JobDescription
									job={job}
									loc={loc}
									authUser={authUser}
									listIndex={listIndex}
									showJobDescription={this.showJobDescription.bind(
										this
									)}
									bindContactDetails={this.bindContactDetails.bind(
										this
									)}
								/>
							)}
						</Container>
					</Content>
				</Body>
			</>
		);
	}
}
const mapStateToProps = (state) => {
	return { authUser: state.user.user, loc: state.intl.locale };
};
const recordCount = 15;
export default connect(mapStateToProps)(withRouter(Jobs));
