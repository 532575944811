const initialState = {
    menu: {},
};
  
const userData = (state = initialState, action) => {
    if (action === undefined) return state;
    switch (action.type) {

        case 'HEADER_MENU':
            return {
                menu: Object.assign({}, action.menu),
            };
        default:
            return state;
    }
};
export default userData;