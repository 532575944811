import { connect } from 'react-redux';

import mapStateToProps from './mapStateToProps';
import mapDispatchToProps from './mapDispatchToProps';

export default InputComponent => {
    return connect(
        mapStateToProps,
        mapDispatchToProps
    )(InputComponent);
};