import { isEmpty } from "../helpers";
import { getUser } from '../Auth/user';

var controller;
const Ajax = {
    call, get,
    abort, getHeader
}

async function call(url, body, method = "POST") {
    var headers = getHeader();
    body = JSON.stringify(body);
    return fetch(url, { method, headers, body })
        .then(res => res.text())        
        .then(response => {            
            if(response.length > 0) {
                try {
                    return JSON.parse(response);
                } catch(e) {
                    return {}
                }                
            } else {
                return {};
            }
        }).catch(error => {throw new Error(error)});
}

async function get(url, body) {
    var headers = getHeader();
    if(!isEmpty(body)) {
        url = new URL(url);
        let query = Object.keys(body)
             .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(body[k]))
             .join('&');
        url += `?${query}`;
    }
    return fetch(url, { headers })
        .then(res => res.text())
        .then(response => {
            if(response.length > 0) {
                try {
                    return JSON.parse(response);
                } catch(e) {
                    return {}
                }                
            } else {
                return {};
            }
        }).catch(error => {throw new Error(error)});
}

function getHeader() {
    var headers = { "Content-Type": "application/json" };
    let user = getUser();
    if(!isEmpty(user)) {
        headers.Authorization = "Basic " + user.ba;
    }
    return headers;
}
/**
 * aborts the ajax
 */
function abort() {
    controller.abort();
}

export default Ajax;