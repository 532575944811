import React from 'react';

function Row(props) {
    //console.log('Check Row props', props)
    return (
        <div className={`row ${props.className || ''}`} {...props}>
            {props.children}
        </div>
    );
}
export default Row;