import React from 'react';
import FormContext from './FormContext';

const Input = ({ label, options, placeholder, onChange, ...props}) => {

    function changeHandler(e) {
        onChange(e.target.name, e.target.value);
    }

    return <FormContext.Consumer>
        {context => {
            const errors = context? context.errors: {};
            return <>
                {label && <label className="form-label">{label}</label>}
                <select
                    value={props.value || ''}
                    className={`form-control ${errors[props.name]? 'has-error': ''}`} 
                    {...props}
                    onChange={changeHandler}>
                        {placeholder && <option value="">{placeholder}</option>}
                        {options.length > 0 && options.map((option, i) => (
                            <option value={option.value} key={i}>{option.text}</option>
                        ))}
                </select>
                {(errors[props.name] && errors[props.name].length > 0) && <div className="help-block error">{errors[props.name]}</div>}
            </>
        }}
    </FormContext.Consumer>
}
export default Input;