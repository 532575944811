import React from 'react';
import { Card, Row } from '../../Theme';
import _job from '../../i18n/job.json';
import noImageAvailable from './../../assets/images/no_image.jpg'
const jobLogoStyle = {
    width: '40px',
    height: "40px",
 float:"left"
}
function JobCard(props) {
    let { job, loc, index, authUser } = props;
    function printExperience() {
        var exp = '';
        var isFresher= true;
        if(job.minExp > 0) {
            let minExpYear = parseInt(job.minExp / 12);
            let minExpMonth = parseInt(job.minExp) - (minExpYear * 12);
            if(minExpYear > 0) { 
                exp += minExpYear === 1? `${minExpYear} Year `: `${minExpYear} Years `;
            }
            if(minExpMonth > 0) { 
                exp += minExpMonth === 1? `${minExpMonth} Month `: `${minExpMonth} Months `;
            }
            isFresher= false;
            exp += ' - ';
        } else {
            exp = 'Upto '
        }
        if(job.maxExp > 0) {
            let maxExpYear = parseInt(job.maxExp / 12);
            let maxExpMonth = parseInt(job.maxExp) - (maxExpYear * 12);
            if(maxExpYear > 0) {
                exp += maxExpYear === 1? `${maxExpYear} Year `: `${maxExpYear} Years `;
            }
            if(maxExpMonth > 0) { 
                exp += maxExpMonth === 1? `${maxExpMonth} Month `: `${maxExpMonth} Months`;
            }
            isFresher= false;
        }
        return isFresher? 'Fresher': exp;
    }
    const showButton = (job, authUser) => {
        if(authUser && Object.keys(authUser).length === 0){
            let isLoggedIn = false;
            return (
                <button type="button" onClick={() => props.showJobDescription(index, job, true, isLoggedIn)} className="btn btn-connect">
                    <i class="bi bi-telephone"></i>
                    {_job[loc].connect_now}
                </button>
            )
        }else if(!job.applied && authUser && Object.keys(authUser).length > 0){
            let isLoggedIn = true;
            return (
                <button type="button" onClick={() => props.showJobDescription(index, job, true, isLoggedIn)} className="btn btn-connect">
                    <i class="bi bi-telephone"></i>
                    {_job[loc].connect_now}
                </button>
            )
        }else{
            let isLoggedIn = true;
            return (
                <button type="button" onClick={() => props.showJobDescription(index, job, true, isLoggedIn)} className="btn btn-des">
                    <i class="bi bi-info-circle"></i>
                    {_job[loc].show_description}
                </button>
            )
        }
        
    }
    return(
        <Card className="job-card col-xs-12" data-aos="fade-up" data-aos-delay="200">
            <div className='row'>
                <div className="col-sm-7 col-md-7 col-lg-9">
                <div className="col-sm-12 p-0 mb-4">
                            <img src={job.imageData && job.imageData !== "" ?  `data:${job.imageContentType};base64,${job.imageData}` : "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAABmJLR0QA/wD/AP+gvaeTAAAD/0lEQVRogc2azU8rVRjGfzMUTERDwlSvpG40XPEfILoogcqS1Nybmxg/ItyFWKOiG03cIXGJturKxF3/BZKbuHJDolG4QNnYBRJCLMW20XvbJkrLvC5Ox1saOjOdMy08yZs0PWfO+zzvOXM+3jMG4eAZ4BXgJeBF4HnAAp5oldeACvA78BvwM/AjUAzJfyBYwEfAr4AEtF+AlVZbA8OzwNdAXYN4p9WADBDrJ/Fh4GOgGiLxTqsDnwOPhU1+CtjtI/FO2wFeCIv8Hfob9W72ELitS/4u0LgC8o41gfeCkk9dIfFOW+mV/B2U+qsm3t4Tt/ySnwQeBHVmWZasr6/LwcGBNBoNaTabcnh4KOl0WqLRqI6IKmqRdMUIGrPN5OSkHB0dSTccHx/L1NSUjoj7qOm8Kz4L2rhpmrKzs9OVvIO9vT0ZGhrSEfFJN/Ix1IoYqOFkMulJ3sHCwoLuUJpwSJttAj4FRt26xw1zc3O+687MzAR1A2qD+H8vOAIsYFmn1bGxMd91R0cDx8lBitYG0BHwFvC4Tov1et133Wq1quMK1Eh5HR4JeFu3xbOzM991z8/Pdd0BLDo/JgAbjYVmfn7e9wvsIJFI6LzI0uJ8wwQSgKETiuFh16n5UoyMjOi4BMV5DuAb9CIhkUhEcrmc7+jv7u5KJBLR7QFBHYL4IYSGZHp62reAeDweBnkB7pmovY82Tk5OfNctFkM7y98ElS3QjoZhGFIsFj2jf3p6KqZphtUDJZNHqQ8tiAiZTMazXjqdxrbtMFwCPAnwL+FEQyKRiOTz+a7Rz+fzYb28jv1jojZwoaDZbFIoFLqWFwoFms1mWO4AqibwV5gtGkb3JcWtLCD+hpCmUUCi0aiUy+WuQ6hUKollWWEOoXuguZDFYjFZWlqSbDYrlUrFcxYql8uSzWZlcXFRYrGYroAMwBtBHo7H47KxsSG2bXuSdsPm5qYkk0kxDCOIgNcAbtDDZi6RSMj+/r4W6cuQy+Vkdna2F/J2izugssSeD62trWlH3A22bcvq6qpfAT/RhhWvB1KpVN+Id2J5edmPgPfbBVh4HOi3t7cHJmBra8uLfI2OI2UF+B4XjI+PuxWHCsvyvO/4DsX5QlbiS1R+/rqjiuIKXBTwB/DFwOn0jjXa7tbMjsI06nLhuuI+8G37H50CGqjF4eGgGPWAGvAmiqMnbnP90uuv9qr43WtA3Flx3+mVvIO7XP0VUyooeQe3UO/EoMk/IMCw6YabqBlgUOS3CSlb0o4I6qK7n73hXHRrp+3cMIFaLwJfhlxiNeAr1EcjA4MFfIj68iRIcthGbYk/AAJvtMI6ZT+NShK/jLpJfA54iouf25SAQy5+bvOnruP/AFW3fPP5MM9tAAAAAElFTkSuQmCC"} class="rounded-circle mr-4" style={jobLogoStyle} alt={job.jobTitle}/>
                        {job.jobTitle && <React.Fragment>
                        <h4>{job.jobTitle}</h4>
                         </React.Fragment>}
                        </div>
                        
                        {job.companyName && <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4 mb-3 p-0 d-flex align-items-center">
                        <i class="bi bi-bank"></i> {job.companyName}
                        </div>}
                        {job.minQualification && <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4 mb-3 p-0 d-flex align-items-center">
                        <i class="bi bi-mortarboard"></i> {job.minQualification}
                        </div>}
                        <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4 mb-3 p-0 d-flex align-items-center">
                        <i class="bi bi-briefcase"></i>{printExperience()}</div>
                        <div className="col-sm-12 col-md-12 col-lg-5 col-xl-5 p-0 d-flex align-items-center">
                        <i class="bi bi-geo-alt"></i> {job.locality}, {job.city}
                        </div>
                        </div>
                        <div className="col-sm-5 col-md-5 col-lg-3 text-right">
                        <div className="col-xs-12 p-0 mb-4 salary">
                        <i class="bi bi-cash-stack"></i> <strong>{job.salaryStartLimit} - {job.salaryEndLimit}</strong> <small>/per month</small>
                        </div>
                            {showButton(job, authUser)}
                        </div>
            </div>
        </Card>
    )
}
export default JobCard;