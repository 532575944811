import React, { useState } from 'react';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { Row, LoadingButton } from '../../Theme';
import _job from '../../i18n/job.json';
import { confirmAlert } from 'react-confirm-alert';
import '../../styles/confirm-alert.css';
import { Ajax } from '../../api';
import web from '../../config/web';
import { toast } from 'react-toastify';
import _profile from '../../i18n/profile.json';
import { Link } from 'react-router-dom';

function JobDescription(props) {
	const myRef = React.useRef();
	let { job, loc, authUser } = props;
	var [state, setState] = useState({
		loading: false,
	});

	const ButtonMailto = ({ mailto }) => {
		return (
			<Link
				to="#"
				onClick={(e) => {
					window.location = 'mailto:' + mailto;
					e.preventDefault();
				}}
			>
				{mailto}
			</Link>
		);
	};

	function confirmToCall() {
		confirmAlert({
			title: `${_job[loc].do_you_want_to_proceed}`,
			message: `${_job[loc].lead_value_will_be_spent} ₹ ${job.employeeLeadValue}`,
			buttons: [
				{
					label: _profile[loc].cancel,
					className: 'btn btn-secondary',
				},
				{
					label: _profile[loc].proceed,
					onClick: callToHR,
					className: 'btn btn-primary',
				},
			],
		});
	}

	function callToHR() {
		setState({ loading: true });
		let params = {
			mobNumber: authUser.mobNumber,
			jobId: job.id,
		};
		Ajax.call(web.baseUrl + 'connect/connecttoemployer', params)
			.then((data) => {
				if (data.status === 'OK') {
					props.bindContactDetails(data.content);
				} else {
					toast.error(data.message);
				}

				setState({ loading: false });
			})
			.catch(() => {
				toast.error('Something went wrong, please try again.');
				setState({ loading: false });
			});
	}

	return (
		<>
			<div ref={myRef} />
			<Modal
				open={true}
				onClose={() => props.showJobDescription('', {}, false)}
				center
				container={myRef.current}
			>
				<div className="modal-dialog modal-dialog-centered job-details">
				<div className="modal-content">
				<div className="modal-header">
               <h5 className="modal-title job-heading-popup">{_job[loc].job_description}</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
                </div>
						<div className="modal-body">
							<Row className="content-panel">
								<div className="col-xs-12">
									<h5>{_job[loc].job_title}: </h5>{' '} <h4>{job.jobTitle}</h4>
								</div>
								
								<div className="col-xs-12">
									<h5>{_job[loc].company_name}: </h5>{' '} <h4>{job.companyName}</h4>
								</div>
								<div className="col-xs-12">
									<h5>{_job[loc].description}: </h5>{' '} <h4>{job.jobDesc}</h4>
								</div>
								<div className="col-xs-6">
								<h5>{_job[loc].education}: </h5>{' '} <h4>{job.minQualification}</h4>
								</div>
								<div className="col-xs-6">
									<h5>{_job[loc].experience}: </h5>{' '}
									<h4>{job.minExp > 0 ? `${job.minExp} Month` : 'Fresher'}</h4>
								</div>
								<div className="col-xs-12">
								<h5>{_job[loc].salary}: </h5>{' '}
									<h4>{job.salaryStartLimit > 0
										? `${job.salaryStartLimit} - `
										: 'Upto'}{' '}
									{job.salaryEndLimit}</h4>
								</div>
								<div className="col-xs-12">
								<h5>{_job[loc].locality}: </h5>{' '} <h4>{job.locality}, {job.city}</h4>
								</div>
							</Row>
							{job.employer && (
								<>
									
										<div className="col-xs-6">
											<h5>
												{_job[loc].mob_number}:{' '}
											</h5>{' '}
											<h4>{job.employer.mobNumber}</h4>
										</div>
										<div className="col-xs-6">
											<h5>{_job[loc].email}: </h5>{' '}
											<h4><ButtonMailto
												mailto={job.employer.emailId.toLowerCase()}
											/></h4>
										</div>
								</>
							)}
						</div>
						{!job.applied && (
							<div className="modal-footer">
								<div className="col-xs-12">
								{!state.loading ? (
									<button
										type="button"
										className="btn-default btn-signup"
										onClick={confirmToCall}
									>
										{_job[loc].call_to_hr}
									</button>
								) : (
									<LoadingButton className="btn btn-signup" />
								)}
								</div>
							</div>
						)}
					</div>
					</div>
			</Modal>
		</>
	);
}
export default JobDescription;
