export const setUser = (user) => {
    localStorage.setItem("user", JSON.stringify({
        username: user.username, lang: user.lang, role: user.role, ba: user.ba
    }));
}

export const getUser = () => JSON.parse(localStorage.getItem("user"));

export const removeUser = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("jf_timestamp");
}

export const setSessionExpire = () => localStorage.setItem("jf_timestamp", new Date().getTime());

export const checkSessionExpire = () => {
    let ts = localStorage.getItem("jf_timestamp");
    let now = new Date().getTime();

    var diff = (now - ts) / 60000;
    let { location } = window;
    let url = location.href;

    if(diff > 60 && (url.search('jfuser') > 0 || url.search('employer') > 0)) {
        removeUser();
        setSessionExpire();
        window.location.href = '/';
    }
}