import React, { useState, useEffect } from "react";
import "./checkbox.css";

function Checkbox(props) {
  var [isChecked, setChecked] = useState(false);
  let { name, value } = props;

  useEffect(() => {
    setChecked(props.checked);
  }, [props.checked]);

  function handleChange() {
    props.handleChecked(name, value, !isChecked);
  }

  return (
    <div data-toggle="buttons" className="btn-group filter-checkbox">
      <label
        className={`btn btn-${
          isChecked ? "custom-success" : "custom-default"
        } btn-checkbox`}
      >
        <input
          type="checkbox"
          name={name}
          value={value}
          checked={isChecked}
          onClick={handleChange}
        />
        <span>{props.label}</span>
      </label>
    </div>
  );
}
export default Checkbox;
