import React from 'react';
import styled from 'styled-components';

function Animate(props) {
    return (
        <Fade>
            {props.children}
        </Fade>
    );
}
const Fade = styled.div`
    -moz-animation: fadein 8s;
    -webkit-animation: fadein 8s;
    -o-animation: fadein 8s;
    animation: fadein .8s;
`
export default Animate;