import React from 'react';
import FormContext from './FormContext';

const Input = ({ label, value, name, onChange, ...props}) => {

    function changeHandler(e) {
        onChange(e.target.name, e.target.value);
    }

    return <FormContext.Consumer>
        {context => {
            const errors = context? context.errors: {};
            return <>
                {label && <label className="form-label">{label}</label>}
                <input type="number" 
                    value={value || ''}
                    className={`form-control ${errors[name]? 'has-error': ''}`} 
                    name={name}
                    {...props}
                    onChange={changeHandler} />
                {(errors[name] && errors[name].length > 0) && <div className="help-block error">{errors[name]}</div>}
            </>
        }}
    </FormContext.Consumer>
}
export default Input;