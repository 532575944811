import React from 'react';
import FormContext from './FormContext';

const Textarea = ({ label, onChange, ...props}) => {

    function changeHandler(e) {
        onChange(e.target.name, e.target.value);
    }

    return <FormContext.Consumer>
        {context => {
            const errors = context? context.errors: {};
            return <>
                {label && <label className="form-label">{label}</label>}
                <textarea type="text" 
                    value={props.value || ''}
                    className={`form-control ${errors[props.name]? 'has-error': ''}`} 
                    {...props}
                    onChange={changeHandler}></textarea>
                {(errors[props.name] && errors[props.name].length > 0) && <div className="help-block error">{errors[props.name]}</div>}
            </>
        }}
    </FormContext.Consumer>
}
export default Textarea;