import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { LastLocationProvider } from 'react-router-last-location';
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import web from "../config/web";
import NoPage from "../Layout/NoPage";
import PageChange from "../lib/PageChange";
import Jobs from "../Employee/Job/Jobs";

const Home = React.lazy(() => import("../Home"));
const Employer = React.lazy(() => import("../Employer"));
const Employee = React.lazy(() => import("../Employee"));
const Signup = React.lazy(() => import("../Auth/Signup"));
const Login = React.lazy(() => import("../Auth/Login"));
const ContactUs = React.lazy(() => import("../Home/ContactUs"));
const PrivacyPolicy = React.lazy(() => import("../Home/PrivacyPolicy"));
const RefundPolicy = React.lazy(() => import("../Home/RefundPolicy"));
const TermsAndConditions = React.lazy(() => import("../Home/TermsAndConditions"));

const ChangeAccountId = React.lazy(() => import("../Auth/ChangeAccountId"));
const EmployeeResetPassword = React.lazy(() =>
  import("../Auth/EmployeeResetPassword")
);
const EmployerResetPassword = React.lazy(() =>
  import("../Auth/EmployerResetPassword")
);

function Routes(props) {
  return (
    <Router basename={web.baseFolder}>
      <LastLocationProvider>
        <HelmetProvider>
          <PageChange />
          <Header {...props} />
          <React.Suspense fallback="...">
            <Switch>
              <Route path="/signup/:role?" component={Signup} />
              <Route path="/login" component={Login} />
              <Route path="/jobs" component={Jobs} />
              <Route path="/change-accountid" component={ChangeAccountId} />
              <Route
                path="/yee/forgot-password"
                component={EmployeeResetPassword}
              />
              <Route
                path="/yer/forgot-password"
                component={EmployerResetPassword}
              />
              <Route path="/contact-us" component={ContactUs} />
              <Route path="/privacy-policy" component={PrivacyPolicy} />
              <Route path="/refund-policy" component={RefundPolicy} />
              <Route path="/terms-and-conditions" component={TermsAndConditions} />

              <Route path="/employer" component={Employer} />
              <Route path="/jfuser" component={Employee} />
              <Redirect from="/home" to="/" />
              <Route exact path="/" component={Home} />
              {/* <Route exact path="/login" component={Login} /> */}
              <Route component={NoPage} />
            </Switch>
          </React.Suspense>
          <Footer />
        </HelmetProvider>
      </LastLocationProvider>
    </Router>
  );
}
export default Routes;
