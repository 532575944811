import { getUser } from '../../Auth/user';

let user = getUser();
var lang = (user && user.lang && user.lang === 'HIN')? 'HIN': 'ENG';

const initialState = {
    locale: lang
};

const intlData = (state = initialState, action) => {
    if (action === undefined) return state;
    switch (action.type) {
        case 'UPDATE_LANGUAGE':
            return {
                locale: action.language
            };
        default:
            return state;
    }
};
export default intlData;