/**
 * checkes the object empty or not
 */
export default function isEmpty(obj) {
    return (
        obj === null ||
        obj === undefined ||
        (obj.hasOwnProperty("length") && obj.length === 0) ||
        (obj.constructor === Object && Object.keys(obj).length === 0)
    );
}
